import logo from './logo.svg';
import UnderConstruction from '../src/assets/imgs/UnderConstruction.jpg'
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={UnderConstruction} alt="Sitio en construcción" />
        <a href="http://www.freepik.com">Designed by Freepik</a>
      </header>
    </div>
  );
}

export default App;
